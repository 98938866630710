<template>
  <div class="w1200">
    <h2>{{info.honorTitle}}</h2>
    <p class="time">发布日期：{{info.createTime}}</p>
    <div v-html="info.contents" class="lawFirmHonorsDetails"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id:'',
      info:''
    }
  },
  mounted(){
    this.id = this.$route.params.id
    this.getInfo()
  },
  methods:{
    getInfo() {
      this.$api.getHonorDetail({id:this.id}).then(res=>{
        this.info = res.data
      })
    },
  }
}
</script>
<style>
  .lawFirmHonorsDetails img{
    max-width: 100%;
  }
</style>
<style scoped lang='stylus'>
  h2{
    text-align: center;
    font-size:32px;
    line-height: 60px;
  }
  .time{
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    margin-bottom:50px;
  }
  .lawFirmHonorsDetails{
    font-size: 16px;
  }
</style>